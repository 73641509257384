/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import "./src/styles/style.scss"
import React from 'React'
import CookiesState from './src/context/cookies/cookiesState'


export const wrapPageElement = ({ element }) => {
  return <CookiesState>{element}</CookiesState>;
};
